import * as React from 'react';
import { useState, useEffect } from "react";
//import TextField from '@mui/material/TextField';
import { TextField, Button, Grid, Box } from '@mui/material';

import Swal from 'sweetalert2';

import '../../css/login.css';

//Redux
import { useDispatch, useSelector } from 'react-redux';
import { tokenLoginUser, loginUser } from '../../redux/userActions'; // Import actions
import { setJobDescription } from '../../redux/queryActions'; // Import actions

//Images
import Logo from '../../assets/images/clogo.png';

//Mobile?
import {isMobile} from 'react-device-detect';

//Navigate
import { useNavigate } from 'react-router-dom';

//React Google Analytics
import ReactGA from 'react-ga4';

//Drag and Drop Images
import { useDropzone } from 'react-dropzone';

function Search () {

    const [description, setDescription] = React.useState('');
    const [images, setImages] = useState([]);

    //Redux Object
    const dispatch = useDispatch();
    const currentUser = useSelector((state) => state.user.currentUser);
    const currentQuery = useSelector((state) => state.query.currentQuery);

    //Navigation
    const navigate = useNavigate();

    // Send pageview with a custom path
    ReactGA.send({ hitType: "pageview", page: "/search", title: "New Customer Search" });

    //Persistent Login
    useEffect(() => {

        const token = localStorage.getItem('C-TradesUserToken');
        if (token) {
            
            //Check the token
            dispatch(tokenLoginUser(token, navigate));
        }
    }, []);
    
    //Image Drag and Drop
    const onDrop = (acceptedFiles) => {
        if (images.length + acceptedFiles.length <= 5) {
            setImages([...images, ...acceptedFiles]);
        } else {
            alert('You can only upload up to 5 images.');
        }
    };

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept: 'image/*',
        multiple: true,
        maxFiles: 5
    });

    //Confirm the desciption is a full description and not a few words before moving onto the next screen
    const confirmDescription = () => {

        if ((!description) || (description.length < 10)) {
            Swal.fire({
                icon: 'error',
                title: 'Description too short',
                text: 'Please provide a more detailed description of your repair or renovation needs.',
            });
        }
        else {

            //dispatch Query
            dispatch(setJobDescription(description, images));

            //Create Google Analytics Event
            ReactGA.event({
                category: "Search",
                action: "Service Requested",
            });

            navigate(`/onboard`, {
                state: { 
                    description: description, 
                    images: images 
                }
            });
        }
    }

    //Login
    const handleLogin = () => {
        
        Swal.fire({
            title: 'Login',
            html: `
              <input type="text" id="email" class="swal2-input" placeholder="Email">
              <input type="password" id="password" class="swal2-input" placeholder="Password">
            `,
            confirmButtonText: 'Login',
            cancelButtonText: 'Cancel',
            showCancelButton: true,
            focusConfirm: false,
            preConfirm: () => {
              const email = Swal.getPopup().querySelector('#email').value
              const password = Swal.getPopup().querySelector('#password').value
    
              return { email: email, password: password }
            }
        }).then((result) => {
            if (result.isConfirmed) {
                const email = result.value.email;
                const password = result.value.password;
                
                //Dispatch the action to create the user
                dispatch(loginUser(email, password));
            }
        });
    }

    const handleRegister = () => {
        // Handle register
        navigate(`/register`);
    };

    /**
     * Business Registration
     */
    const handleBusinessRegistration = () => {
        // Handle business registration
        navigate(`/launch`);
    };

    //Confirm if we are already logged in
    if (currentUser) {
        navigate(`/dashboard`);
    }
    else {
        return (
            <>
            <div className="searchPanel">
                <img src={Logo} className='mainlogo' alt="C-Trades Logo" />
                <div className="searchPanelContent">
                    <h1>Please describe your home repair or renovation needs.</h1>
                    <TextField
                        id="outlined-multiline-static"
                        label="Description"
                        multiline
                        rows={4}
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        variant="outlined"
                        fullWidth
                    />
                    <hr className='custom-hr'/>
                    <div {...getRootProps({ className: 'dropzone' })}>
                        <input {...getInputProps()} />
                        <p>Drag 'n' drop pictures here to help describe the problem, or click to select files (up to 5 images)</p>
                    </div>
                    <div className="image-preview">
                        {images.map((file, index) => (
                            <img
                                key={index}
                                src={URL.createObjectURL(file)}
                                alt={`preview ${index}`}
                                className="image-preview-thumbnail"
                            />
                        ))}
                    </div>
                    <button className="submitButtonStyle" onClick={confirmDescription}>Submit</button>
                    <div className='orPanel'> OR </div>
                    <Box sx={{ mt: 2 }}>
                        <Grid container spacing={2} justifyContent="center">
                            <Grid item>
                                <Button variant="contained" className="loginButtonStyle" onClick={handleLogin}>
                                    Login
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button variant="contained" className="registerButtonStyle" onClick={handleRegister}>
                                    Register
                                </Button>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} justifyContent="center">
                            <Grid item>
                                <Button variant="contained" className="businessRegButtonStyle" onClick={handleBusinessRegistration}>
                                    Business Registration
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                    {/*<button className="loginButtonStyle" onClick={handleLogin}>Login</button>*/}
                    <div className="fixed-text">
                        <span>"Let us connect</span>
                        <span>you with reliable,</span>
                        <span>trusted</span>
                        <span>professionals."</span>
                    </div>
                </div>
            </div>
            
            </>
        )
    }
}

export default Search;