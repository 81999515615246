import { api } from "./config/axiosConfig"

//SweetAlert2
import Swal from 'sweetalert2'

export const businessAPI = {

    createBusiness: async function (name, ownerName, phone, email, address, city, country, lat, long, website, description) {

        const response = await api.request({
          url: `/business/createBusiness`,
          method: "POST",
          data: {
            name: name,
            contact_name: ownerName,
            phone: phone,
            email: email,
            address: address,
            city: city,
            country: country,
            latitude: lat,
            longitude: long,
            website: website,
            description: description,
          },
        })
        
        // returning the product returned by the API
        return response
    },

    callChatGPT: async function (description) {

        const response = await api.request({
          url: `/quote/callChatGPT`,
          method: "POST",
          params: {
            prompt: description,
          },
        })
        
        // returning the product returned by the API
        return response
    },

    viewRequests: async function (userId) {

        const response = await api.request({
          url: `/quote/viewRequests`,
          method: "GET",
          params: {
            userId: userId,
          },
        })
        
        // returning the product returned by the API
        return response
    },

    cancelRequest: async function (requestID) {

        console.log(requestID);

        const response = await api.request({
          url: `/quote/cancelRequest`,
          method: "POST",
          data: {
            requestId: requestID,
          }
        })
        
        // returning the product returned by the API
        return response
    },

    getBusinessInfo: async function (token) {

        try {
          const response = await api.request({
            url: `/business/getBusinessInfo`,
            method: "POST",
            data: {
              token: token,
            },
          })
          
          // returning the product returned by the API
          return response
        } catch (error) {
          
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Failed to get business info',
          })
        }
    },

    subscribe: async function (businessId, amount) {

        const response = await api.request({
          url: `/payment/sendPayment`,
          method: "POST",
          data: {
            businessId: businessId,
            amount: amount,
          },
        })
        
        // returning the product returned by the API
        return response
    },

    cancelSubscription: async function (businessId) {

        const response = await api.request({
          url: `/payment/cancelSubscription`,
          method: "POST",
          data: {
            businessId: businessId,
          },
        })
        
        // returning the product returned by the API
        return response
    }
};