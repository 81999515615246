import './App.css';

//Search
import Search from './components/SearchComponent/searchComponent';

//Forgot Password
import ForgotPassword from './components/UserComponent/forgotPasswordComponent';

//Forgot Password
import ResetPassword from './components/UserComponent/resetPasswordComponent';

//Client Portal
import Onboard from './components/UserComponent/onboardComponent';

//Client Register without a project
import Register from './components/UserComponent/registerComponent';

//Dashboard
import Dashboard from './components/ProfileComponent/dashboardComponent';

/*-------------------Business Components-------------------*/
//Register Business
import BusinessRegistration from './components/BusinessComponent/businessRegistrationComponent';

//Business Dashboard
import BusinessDashboard from './components/BusinessComponent/businessDashboard';
/*-------------------Business Components-------------------*/

//Routing for Reactjs
import { Route, Routes } from 'react-router-dom';
import { useParams } from 'react-router-dom';

//Redux
import { Provider } from 'react-redux';
import store from './redux/store'; // Import your store

//Google Analytics
import ReactGA from 'react-ga4';

const ResetPasswordRoute = () => {
  const { token } = useParams(); // Get the token parameter from the URL

  // You can pass the token to your ResetPassword component or perform any other logic here
  return <ResetPassword token={token} />;
};

const AccessBusinessDashboard = () => {
  const { token } = useParams(); // Get the token parameter from the URL

  return <BusinessDashboard token={token}/>;
}

//App Routes
const App = () => {

    ReactGA.initialize('G-YRSFPZTCX9');

    return (
        <>
          <Provider store={store}>
            <Routes>
              <Route path="/" element={<Search />} />
              <Route path="/forgotPassword" element={<ForgotPassword />} />
              <Route path="/reset-password/:token" element={<ResetPasswordRoute />} />
              <Route path="/onboard" element={<Onboard />} />
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/register" element={<Register />} />
      
              <Route path="/launch" element={<BusinessRegistration />} />
              <Route path="/business/:token" element={<AccessBusinessDashboard />} />

              <Route path="*" element={<Search />} />
            </Routes>
          </Provider>
        </>
    );
}

export default App;
