import React, { useState, useEffect } from 'react';
import { Container, Typography, Box, TextField, Button, List, ListItem, ListItemText, Divider, Pagination, Card, CardContent, Grid } from '@mui/material';

//Stripe
import { Elements, useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

//API
import { businessAPI } from '../../api/businessAPI';

//SweetAlert
import Swal from 'sweetalert2';

//Background Image
import background from '../../assets/images/Apron.jpg';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

const BusinessDashboard = (prop) => {

    const [business, setBusiness] = useState('');
    const [email, setEmail] = useState('');
    const [subscription, setSubscription] = useState('');
    const [payments, setPayments] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 5;
    
    //UseEffect to get the business information
    useEffect(() => {

        //Get the business information
        businessAPI.getBusinessInfo(prop.token).then((response) => {

            //Check if the response is successful
            if (response.status === 200) {
                console.log(response.data);

                //Set the Business
                setBusiness(response.data);
                setEmail(response.data.email);
                setSubscription(response.data.payment.stripeSubscription);
                setPayments(response.data.payment);
            } else {

                //Display error message
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Failed to get business info',
                })
            }
        });
    }, []);
    
    /*const [payments, setPayments] = useState([
        { id: 1, date: '2023-12-01', amount: '$10.00' },
        { id: 2, date: '2023-11-01', amount: '$10.00' },
        { id: 3, date: '2023-10-01', amount: '$10.00' },
        { id: 4, date: '2023-09-01', amount: '$10.00' },
        { id: 5, date: '2023-08-01', amount: '$10.00' },
        { id: 6, date: '2023-07-01', amount: '$10.00' },
        { id: 7, date: '2023-06-01', amount: '$10.00' },
        { id: 8, date: '2023-05-01', amount: '$10.00' },
        { id: 9, date: '2023-04-01', amount: '$10.00' },
        { id: 10, date: '2023-03-01', amount: '$10.00' },
        { id: 12, date: '2023-02-01', amount: '$10.00' },
        { id: 13, date: '2023-01-01', amount: '$10.00' },
    ]);*/

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };

    const handleEmailSubmit = () => {
        // Handle email change logic
        console.log('Email changed to:', email);
    };

    const handleSubscriptionCancel = () => {
        // Handle subscription cancel logic
        
        //Cancel the subscription
        businessAPI.cancelSubscription(business._id).then((response) => {
            console.log(response.data);

            //Check if the response is successful
            if (response.status === 200) {

                //End Date
                const endDate = new Date(response.data.current_period_end).toLocaleDateString();
            
                 // Display success message
                Swal.fire({
                    icon: 'success',
                    title: 'Success',
                    text: 'Subscription cancelled successfully, your subscription will end on ' + endDate,
                }).then((result) => {
                    if (result.isConfirmed) {
                        window.location.reload();
                    }
                });
            } else {
                //Display error message
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Failed to cancel subscription',
                })
            }
        });
    };

    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };

    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const currentPayments = payments && payments.stripeSubscription && payments.stripeSubscription.length > 0 ? payments.stripeSubscription.slice(startIndex, endIndex) : [];


    if (business) {
        return (
            <Box 
                sx={{
                    backgroundImage: `url(${background})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    minHeight: '100vh',
                    padding: 4,
                }}
            >
                <Elements stripe={stripePromise}>
                    <BusinessDashboardContent
                        business={business}
                        email={email}
                        subscription={subscription}
                        payments={payments}
                        currentPage={currentPage}
                        itemsPerPage={itemsPerPage}
                        handleEmailChange={handleEmailChange}
                        handleEmailSubmit={handleEmailSubmit}
                        handleSubscriptionCancel={handleSubscriptionCancel}
                        handlePageChange={handlePageChange}
                        currentPayments={currentPayments}
                    />
                </Elements>
            </Box>
        );
    }
};

const BusinessDashboardContent = ({
    business,
    email,
    subscription,
    payments,
    currentPage,
    itemsPerPage,
    handleEmailChange,
    handleEmailSubmit,
    handleSubscriptionCancel,
    handlePageChange,
    currentPayments,
}) => {
   
    const stripe = useStripe();

    if (!business) {
        return null;
    }

    const handleSubscriptionPurchase = async () => {

        //Subscribe to the business payment plan
        const response = await businessAPI.subscribe(business._id, 10);

        console.log(response.data);

        //Check if the response is successful
        if (response.status === 200) {
            
            const sessionId = response.data.id;

            // Redirect to Stripe Checkout
            const { error } = await stripe.redirectToCheckout({ 
                sessionId: sessionId
            });
        }
    };

    return (
        <Container maxWidth="md" sx={{ mt: 4, mb: 4, backgroundColor: 'rgba(0, 128, 128, 0.65)', padding: 4, borderRadius: 5 }}>
            <Grid sx={{ mt: 4, mb: 4 }}>
                <Typography variant="h4" component="h1" gutterBottom align="center" color={'white'}>
                    {business.name} Dashboard
                </Typography>

                {/* Change Email Section */}
                {/*<Grid container spacing={4}>
                    <Grid item xs={12} md={12}>
                            <Card>
                                <CardContent>
                                    <Typography variant="h6" component="h2" gutterBottom>
                                        Change Email
                                    </Typography>
                                    <TextField
                                        label="New Email"
                                        value={email}
                                        onChange={handleEmailChange}
                                        fullWidth
                                        margin="normal"
                                    />
                                    <Button variant="contained" color="primary" onClick={handleEmailSubmit}>
                                        Change Email
                                    </Button>
                                </CardContent>
                        </Card>
                    </Grid>
                </Grid>*/}

                {/* Purchase Subscription Section */}
                <Grid item xs={12} md={12} sx={{ mt: 2 }}>
                    <Card>
                        <CardContent>
                            <Typography variant="h6" component="h2" gutterBottom>
                                Purchase Subscription
                            </Typography>
                            { payments.stripeCustomerId ? (
                                <Box>
                                    <Typography variant="body1" gutterBottom>
                                        You are already on a subscription plan.
                                    </Typography>
                                    <Button variant="contained" color="error" onClick={handleSubscriptionCancel}>
                                        Cancel Subscription
                                    </Button>
                                </Box>
                            ) : (
                                <Button variant="contained" color="primary" onClick={handleSubscriptionPurchase}>
                                    Purchase
                                </Button>
                            ) }
                        </CardContent>
                    </Card>
                </Grid>

                <Divider />

                {/* List Previous Subscription Payments */}
                <Grid item xs={12} sx={{ mt: 2 }}>
                    <Card>
                         <CardContent>
                            <Typography variant="h6" component="h2" gutterBottom>
                                Previous Subscription Payments
                            </Typography>
                            <List>
                                {currentPayments.map((payment) => (
                                    <ListItem key={payment.id}>
                                    <ListItemText primary={`Date: ${new Date(payment.subscriptionDate).toLocaleDateString()}`} secondary={`Amount: $${payment.amount.toFixed(2)}`} />                            </ListItem>
                                ))}
                            </List>
                            <Pagination
                                count={Math.ceil(payments.length / itemsPerPage)}
                                page={currentPage}
                                onChange={handlePageChange}
                                sx={{ mt: 2, display: 'flex', justifyContent: 'center' }}
                            />
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Container>
    );
};

export default BusinessDashboard;
        