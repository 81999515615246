import React, { useState, useEffect, useRef } from 'react';
import { TextField, Button, Container, Typography, Box } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import StoreIcon from '@mui/icons-material/Store';
import PersonIcon from '@mui/icons-material/Person';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import HomeIcon from '@mui/icons-material/Home';
import LanguageIcon from '@mui/icons-material/Language';

//Import Weld.png from assets/images
import Weld from '../../assets/images/Weld.jpg';

//API
import { businessAPI } from '../../api/businessAPI';

//SweetAlert2
import Swal from 'sweetalert2';

//Loading
import ReactLoading from 'react-loading';

const BusinessRegistrationComponent = () => {
    const [businessName, setBusinessName] = useState('');
    const [ownerName, setOwnerName] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [address, setAddress] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [country, setCountry] = useState('');
    const [latitude, setLatitude] = useState('');
    const [longitude, setLongitude] = useState('');
    const [website, setWebsite] = useState('');
    const [description, setDescription] = useState('');

    const [loading, setLoading] = useState(false);

    //Google Places API
    const autoCompleteRef = useRef();
    const inputRef = useRef();
    const options = {
        types: ['geocode'],
        componentRestrictions: { country: ["ca","us"] }
    };

    //Use Effect for Google Places API
    useEffect(() => {
        autoCompleteRef.current = new window.google.maps.places.Autocomplete(document.getElementById('address'), options);
        autoCompleteRef.current.addListener('place_changed', handlePlaceSelect);
    }, []);

    const handlePlaceSelect = () => {
        const addressObject = autoCompleteRef.current.getPlace();
        const address = addressObject.address_components;

        if (address) {

            setAddress(addressObject.formatted_address);

            //Get the city and state
            for (let i = 0; i < address.length; i++) {
                if (address[i].types.includes('locality')) {
                    setCity(address[i].long_name);
                }
                if (address[i].types.includes('administrative_area_level_1')) {
                    setState(address[i].long_name);
                }
                if (address[i].types.includes('country')) {
                    setCountry(address[i].long_name);
                }
            }

            setLatitude(addressObject.geometry.location.lat());
            setLongitude(addressObject.geometry.location.lng());
        }
    }

    const handlePhoneChange = (e) => {
        const value = e.target.value.replace(/\D/g, ''); // Remove non-digit characters
        if (value.length <= 10) {
            setPhone(value);
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        
        //Error handling
        if (!businessName) {
            Swal.fire({
                title: 'Error',
                text: 'Please enter the business name',
                icon: 'error',
                confirmButtonText: 'Ok'
            });
            return;
        }
        else if (!ownerName) {
            Swal.fire({
                title: 'Error',
                text: 'Please enter the owner\'s name',
                icon: 'error',
                confirmButtonText: 'Ok'
            });
            return;
        }
        else if (!phone)  {
            Swal.fire({
                title: 'Error',
                text: 'Please enter the phone number',
                icon: 'error',
                confirmButtonText: 'Ok'
            });
            return;
        }
        else if (!email) {
            Swal.fire({
                title: 'Error',
                text: 'Please enter the email',
                icon: 'error',
                confirmButtonText: 'Ok'
            });
            return;
        }
        else if (!address) {
            Swal.fire({
                title: 'Error',
                text: 'Please enter the address',
                icon: 'error',
                confirmButtonText: 'Ok'
            });
            return;
        }
        else if (!city) {
            Swal.fire({
                title: 'Error',
                text: 'The address is invalid. Please select an address from the dropdown after typing the address',
                icon: 'error',
                confirmButtonText: 'Ok'
            });
            return;
        }
        else if (!country) {
            Swal.fire({
                title: 'Error',
                text: 'The address is invalid. Please select an address from the dropdown after typing the address',
                icon: 'error',
                confirmButtonText: 'Ok'
            });
            return;
        }
        else if (!description) {
            Swal.fire({
                title: 'Error',
                text: 'Please enter a description of the products or services your business provides',
                icon: 'error',
                confirmButtonText: 'Ok'
            });
            return;
        }

        setLoading(true);

        try {

            // Call the API to register the business
            const response = await businessAPI.createBusiness(businessName, ownerName, phone, email, address, city, country, latitude, longitude, website, description);

            // Check if the request was successful
            if (response.status === 201) {
                
                Swal.fire({
                    title: 'Success',
                    text: 'Business Registered Successfully',
                    icon: 'success',
                    confirmButtonText: 'Ok'
                });

                // Clear form fields
                setBusinessName('');
                setOwnerName('');
                setPhone('');
                setEmail('');
                setAddress('');
                setCity('');
                setState('');
                setCountry('');
                setLatitude('');
                setLongitude('');
                setWebsite('');
                setDescription('');
            }
            else {
                Swal.fire({
                    title: 'Error',
                    text: 'An error occurred. Please try again later.',
                    icon: 'error',
                    confirmButtonText: 'Ok'
                });
            }
        } catch (error) {
            Swal.fire({
                title: 'Error',
                text: error.message,
                icon: 'error',
                confirmButtonText: 'Ok'
            });
        } finally {
            setLoading(false);
        }
    };

    // Return the JSX component
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                minHeight: '100vh',
                textAlign: 'center',
                backgroundImage: `url(${Weld})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
            }}
        >
            {loading && (
                <Box
                    sx={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        zIndex: 9999,
                    }}
                >
                    <ReactLoading type="spin" color="#fff" height={50} width={50} />
                </Box>
            )}
            <Box
                sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'rgba(255, 255, 255, 0.15)', // Semi-transparent overlay
                    zIndex: 1,
                }}
            />
            <Container maxWidth="sm" 
                sx={{
                    position: 'relative',
                    zIndex: 2,
                    backgroundColor: 'rgba(255, 255, 255, 0.9)',
                    padding: 4,
                    borderRadius: 3,
                }}
            >
                <Box sx={{ mt: 4, textAlign: 'center' }}>
                    <Typography variant="h4" component="h1">
                        Register 
                    </Typography>
                    <Typography variant="h6" component="p" gutterBottom>
                        Your Business
                    </Typography>
                    <form onSubmit={handleSubmit}>

                        <TextField
                            label="Business Name"
                            value={businessName}
                            onChange={(e) => setBusinessName(e.target.value)}
                            fullWidth
                            margin="normal"
                            required
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <StoreIcon />
                                    </InputAdornment>
                                ),
                            }}
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    borderRadius: '1rem', // Adjust the value as needed
                                },
                            }}
                        />

                        <TextField
                            label="Owner's Name"
                            value={ownerName}
                            onChange={(e) => setOwnerName(e.target.value)}
                            fullWidth
                            margin="normal"
                            //required
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <PersonIcon />
                                    </InputAdornment>
                                ),
                            }}
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    borderRadius: '1rem', // Adjust the value as needed
                                },
                            }}
                        />

                        <TextField
                            label="Email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            fullWidth
                            margin="normal"
                            required
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <EmailIcon />
                                    </InputAdornment>
                                ),
                            }}
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    borderRadius: '1rem', // Adjust the value as needed
                                },
                            }}
                        />

                        <TextField
                            label="Phone"
                            value={phone}
                            onChange={handlePhoneChange}
                            fullWidth
                            margin="normal"
                            //required
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <PhoneIcon />
                                    </InputAdornment>
                                ),
                            }}
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    borderRadius: '1rem', // Adjust the value as needed
                                },
                            }}
                        />
                    
                        <TextField
                            id="address"
                            label="Address"
                            value={address}
                            onChange={(e) => setAddress(e.target.value)}
                            fullWidth
                            margin="normal"
                            inputRef={inputRef}
                            required
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <HomeIcon />
                                    </InputAdornment>
                                ),
                            }}
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    borderRadius: '1rem', // Adjust the value as needed
                                },
                            }}
                        />

                        <TextField
                            label="Website"
                            value={website}
                            onChange={(e) => setWebsite(e.target.value)}
                            fullWidth
                            margin="normal"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <LanguageIcon />
                                    </InputAdornment>
                                ),
                            }}
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    borderRadius: '1rem', // Adjust the value as needed
                                },
                            }}
                        />

                        <TextField
                            label="Description of Products or Services your business provides"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            fullWidth
                            margin="normal"
                            multiline
                            rows={4}
                            required
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    borderRadius: '1rem', // Adjust the value as needed
                                },
                            }}
                        />

                        <Button type="submit" variant="contained" color="primary" fullWidth sx={{ mt: 2, padding: 1, borderRadius: '1rem', fontSize: '1.2rem', fontWeight: 'bold' }}>
                            Register
                        </Button>
                    </form>
                </Box>
            </Container>
        </Box>
    );
};

export default BusinessRegistrationComponent;